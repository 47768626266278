// you started here for the server modification
import React, { useState, useEffect } from 'react';
import { BrowserRouter, BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import { Element } from 'react-scroll';
import Navbar from './components/NavBar';
import Homepage from './pages/Homepage';
import CompanyDetails from './pages/CompanyDetails';
import Wines from './pages/Wines';
import Contact from './pages/Contact';
import Basket from './pages/Basket'; // Adjust the path if necessary
import { FaArrowUp } from 'react-icons/fa';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js'; // Import Stripe
import './App.css';
import ItemAddedModal from './components/ItemAddedModal';
import WineDetails from './components/WineDetails';
import Preloader from './components/Preloader';
import Footer from './pages/Footer';
import SuccessModal from './components/SuccessModal';


//import 'leaflet/dist/leaflet.css';



interface BasketItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  thumbnail: string;
}

console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

function App() {
  const [showScroll, setShowScroll] = useState(false);
  const [basketItems, setBasketItems] = useState<BasketItem[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [showItemAddedModal, setShowItemAddedModal] = useState(false);
  const [showBasketModal, setShowBasketModal] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showWineDetailsModal, setShowWineDetailsModal] = useState(false);
  const [wineDetails, setWineDetails] = useState({
    id: '',
    name: '',
    description: '',
    price: 0,
    thumbnail: '',
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const basketCount = basketItems.reduce((total, item) => total + item.quantity, 0);

  const openWineDetailsModal = (id: string, name: string, description: string, price: number, thumbnail: string,) => {
    setWineDetails({ id, name, description, price, thumbnail });
    setShowWineDetailsModal(true);
  };

  const closeWineDetailsModal = () => {
    setShowWineDetailsModal(false);
  };

  const openItemAddedModal = () => {
    setShowItemAddedModal(true);
  };

  const closeItemAddedModal = () => {
    setShowItemAddedModal(false);
  };

  const openBasketModal = () => {
    setShowBasketModal(true);
  };

  const closeBasketModal = () => {
    setShowBasketModal(false);
  };

  const addToBasket = (productId: string, productName: string, productPrice: number, thumbnail: string, showModal: boolean = true) => {
    setBasketItems((prevItems) => {
      const existingItem = prevItems.find(item => item.id === productId);

      if (existingItem) {
        return prevItems.map(item =>
          item.id === productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        const newItem: BasketItem = {
          id: productId,
          name: productName,
          price: productPrice,
          quantity: 1,
          thumbnail: thumbnail,
        };
        return [...prevItems, newItem];
      }
    });

    setSelectedProductName(productName);
    if (showModal) {
      openItemAddedModal();
    }
  };

  const addToBasketFromView = (productId: string, productName: string, productPrice: number, thumbnail: string, showModal: boolean = true) => {
    setBasketItems((prevItems) => {
      const existingItem = prevItems.find(item => item.id === productId);

      if (existingItem) {
        return prevItems.map(item =>
          item.id === productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        const newItem: BasketItem = {
          id: productId,
          name: productName,
          price: productPrice,
          quantity: 1,
          thumbnail: thumbnail,
        };
        return [...prevItems, newItem];
      }
    });
  }

  const removeFromBasket = (id: string) => {
    setBasketItems((prevItems) => {
      return prevItems
        .map(item =>
          item.id === id
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
        .filter(item => item.quantity > 0);
    });
  };

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScroll]);

  // Check for the checkoutSuccess query parameter on load
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Check if the modal has been shown before by looking at localStorage
  const modalShown = localStorage.getItem('checkoutSuccessModalShown');
  
    if (searchParams.get('checkoutSuccess') === 'true') {
      setShowSuccessModal(true);

      
    }
  }, [location]);

  // Close modal and redirect to the homepage
  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate('/'); // Redirect to homepage
  };

  const handleCheckout = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL; // Ensure this is correctly set
      console.log('API URL:', apiUrl); // Check the API URL
      const response = await axios.post(`${apiUrl}/create-checkout-session`, {
        items: basketItems.map(item => ({
          image: item.thumbnail,
          name: item.name,
          price: item.price,
          quantity: item.quantity
        }))
      });
  
      const { id: sessionId } = response.data;
      const stripe = await stripePromise;
      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error('Stripe checkout error:', error);
        } else {
          // Show the success modal upon successful checkout redirection
        setShowSuccessModal(true);
        }
      }
    } catch (error) {
      console.error('Checkout error:', error);
    }
  };
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    
    <div>
      
      <Navbar basketCount={basketCount} openBasketModal={openBasketModal} />
      <Element name="homepage" className="section">
        <Homepage />
      </Element>
      <Element name="company-details" className="section">
        <CompanyDetails />
      </Element>
      <Element name="wines" className="section">
        <Wines 
          setSelectedProduct={setSelectedProduct}
          addToBasket={addToBasket}
          openBasketModal={openBasketModal}
          basketItems={basketItems}
          removeFromBasket={removeFromBasket}
          openWineDetailsModal={openWineDetailsModal} // Pass the openWineDetailsModal function
        />
      </Element>
      
      <ItemAddedModal 
        show={showItemAddedModal}
        productName={selectedProductName}
        onClose={closeItemAddedModal}
        openBasketModal={() => {
          closeItemAddedModal();
          setTimeout(openBasketModal, 300);
        }}
        closeItemAddedModal={closeItemAddedModal}
      />

      <WineDetails
        show={showWineDetailsModal}
        wineId={wineDetails.id}
        wineName={wineDetails.name}
        wineDescription={wineDetails.description}
        winePrice={wineDetails.price}
        wineThumbnail={wineDetails.thumbnail}
        onClose={closeWineDetailsModal}
        addToBasket={addToBasket}
        addToBasketFromView={addToBasketFromView}
        openBasketModal={() => {
          closeWineDetailsModal();
          setTimeout(openBasketModal, 300);
        }}
        closeWineDetailsModal={closeWineDetailsModal}
      />

      {showBasketModal && (
        <>
          <div className="modal-backdrop fade show" style={{ zIndex: 1040 }} />
          <div 
            className="modal fade show" 
            style={{ display: 'block', zIndex: 1050 }} 
            id="basketModal" 
            tabIndex={-1} 
            aria-labelledby="basketModalLabel" 
            aria-hidden="false"
          >
            <div className="modal-dialog" style={{ maxWidth: basketItems.length === 0 ? '600px' : '500px' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-center w-100"  style={{ fontFamily: "'Special Elite', system-ui"}} id="basketModalLabel">Basket</h5>
                  <button 
                    type="button" 
                    className="btn-close" 
                    onClick={closeBasketModal} 
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Basket 
                    basketItems={basketItems} 
                    removeFromBasket={removeFromBasket}
                    addToBasket={addToBasket} 
                  />
                </div>
                <div className="modal-footer" style={{ justifyContent: 'center' }}>
                  <button type="button" className="btn btn-dark" style={{ fontFamily: "'Special Elite', system-ui", fontSize: '14px', paddingTop: '4px',  // Increase padding-top to move text down
                paddingBottom: '1px', // Optional: balance padding if needed
                paddingLeft: '8px',
                paddingRight: '8px' }} onClick={closeBasketModal}>Keep Shopping</button>
                  <button type="button" 
                  className="btn btn-dark" style={{ fontFamily: "'Special Elite', system-ui", fontSize: '14px', paddingTop: '4px',  // Increase padding-top to move text down
                    paddingBottom: '1px', // Optional: balance padding if needed
                    paddingLeft: '8px',
                    paddingRight: '8px' }}
                  onClick={handleCheckout}
                  disabled={basketItems.length === 0}
                  >Proceed to Checkout</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Element name="contact" className="section">
        <Contact />
      </Element>

      <Element>
        <Footer />
      </Element>

      <FaArrowUp 
        className="scrollTop" 
        onClick={scrollTop} 
        style={{ display: showScroll ? 'block' : 'none' }}
      />

      {/* Render SuccessModal */}
    <SuccessModal 
      show={showSuccessModal} 
      onClose={handleCloseModal} 
    />
    </div>
    
  );
}

export default App;
