//started from here
import React from 'react';

interface WineDetailsProps {
  show: boolean;
  wineId: string;
  wineName: string | null;
  wineDescription: string;
  winePrice: number;
  wineThumbnail: string;
  onClose: () => void;
  addToBasket: (productId: string, productName: string, productPrice: number, thumbnail: string) => void;
  addToBasketFromView: (productId: string, productName: string, productPrice: number, thumbnail: string) => void;
  openBasketModal: () => void;
  closeWineDetailsModal: () => void;
}

const WineDetails: React.FC<WineDetailsProps> = ({
  show,
  wineId,
  wineName,
  wineDescription,
  winePrice,
  wineThumbnail,
  onClose,
  addToBasketFromView,
  openBasketModal,
  closeWineDetailsModal,
}) => {
  if (!show) return null;

  return (
    <div
      className="modal fade show d-flex align-items-center justify-content-center"
      style={{
        display: show ? 'block' : 'none',
        zIndex: 1050,
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Backdrop styling
      }}
      tabIndex={-1}
      role="dialog"
      onClick={onClose} // Close modal if backdrop is clicked
    >
      <div
        className="modal-dialog"
        role="document"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title text-center w-100"  style={{ fontFamily: "'Special Elite', system-ui", fontWeight: 'bold', textDecoration: 'underline' }}>{wineName ? wineName : 'Wine Details'}</h5>
            {/*<button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>*/}
          </div>
          <div className="modal-body text-center w-100">
            <p style={{ fontFamily: "'Special Elite', system-ui", fontSize: '18px', whiteSpace: 'pre-wrap' }}>{wineDescription}</p>
            <p style={{ fontFamily: "'Special Elite', system-ui",fontWeight: 'bold', fontSize: '20px', marginTop: '15px', marginBottom: '-10px' }}>Price: €{winePrice.toFixed(2)}</p>
          </div>
          <div className="modal-footer" style={{ justifyContent: 'center' }}>
            <button
              type="button"
              className="btn btn-dark mt-2 text-center"
              style={{ fontFamily: "'Special Elite', system-ui", fontSize: '14px', paddingTop: '4px',  // Increase padding-top to move text down
                paddingBottom: '1px', // Optional: balance padding if needed
                paddingLeft: '8px',
                paddingRight: '8px' }}
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="btn btn-dark mt-2"
              style={{ fontFamily: "'Special Elite', system-ui", fontSize: '14px', paddingTop: '4px',  // Increase padding-top to move text down
                paddingBottom: '1px', // Optional: balance padding if needed
                paddingLeft: '8px',
                paddingRight: '8px' }}
              onClick={() => {
                addToBasketFromView(wineId, wineName || 'Wine', winePrice, wineThumbnail);
                closeWineDetailsModal();
                setTimeout(openBasketModal, 300); // Small delay for a smoother transition
              }}
            >
              Add to Basket
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WineDetails;
