import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  // Specify the type of useRef for form
  const form = useRef<HTMLFormElement | null>(null);
  const [messageSent, setMessageSent] = useState(false); // State to handle message sent status

  // Type the event explicitly as React.FormEvent
  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    if (form.current) {
      const userName = (form.current as any).user_name.value; // Get user's name
      const userEmail = (form.current as any).user_email.value; // Get user's email

      // First, send the email to yourself (admin)
      emailjs
        .send(
          'service_g5l693w', // Replace with your service ID
          'template_atho68o', // Replace with your admin template ID
          {
            user_name: userName, // User's name
            user_email: userEmail, // User's email
            subject: (form.current as any).subject.value, // User's subject
            message: (form.current as any).message.value, // User's message
            from_name: 'InCoRo', // Set the From Name as "InCoRo"
          },
          '-l37mNAjxF84JyYyE' // Replace with your public key
        )
        .then(
          () => {
            console.log('Email to admin sent successfully!');
            setMessageSent(true); // Set the message sent state to true

            // Reset the form after the email is successfully sent
            form.current?.reset();
          },
          (error) => {
            console.log('Failed to send email to admin...', error.text);
          }
        );

      // Second, send a confirmation email to the user
      emailjs
        .send(
          'service_g5l693w', // Same service ID
          'template_cjs0mx6', // Replace with your user confirmation template ID
          {
            user_name: userName, // User's name
            user_email: userEmail, // User's email address
            subject: (form.current as any).subject.value, // User's subject
            message: (form.current as any).message.value, // User's message
            from_name: 'InCoRo', // Set the From Name as "InCoRo"
          },
          '-l37mNAjxF84JyYyE' // Same public key
        )
        .then(
          () => {
            console.log('Confirmation email sent to user!');
            setMessageSent(true); // Set the message sent state to true

            // Reset the form after sending the confirmation email
            form.current?.reset();
          },
          (error) => {
            console.log('Failed to send confirmation email to user...', error.text);
          }
        );
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4" style={{ fontFamily: "'Special Elite', system-ui" }}>Contact Us</h1>
      
      {/* Success message */}
      {messageSent && (
        <div className="alert alert-success text-white" role="alert" style={{ backgroundColor: '#059652' }}>
          Your message has been sent!
        </div>
      )}

      <div className="row">
        {/* Map container with a white background, padding, and rounded corners */}
        <div className="col-12 col-md-6 mb-4">
          <div className="map-container" style={{
            backgroundColor: 'white', // White background
            padding: '20px',           // Padding for envelope-like appearance
            borderRadius: '15px',      // Rounded corners for the "envelope"
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Light shadow for depth
            height: '270px',           // Fixed height for the map
            overflow: 'hidden'         // Prevent map overflow from rounded corners
          }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31724.91507286947!2d13.1576117!3d38.1319238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1319e7ec13e60097%3A0x3099527c1adbd7cf!2s90044%20Carini%2C%20PA%2C%20Italy!5e0!3m2!1sen!2sit!4v1725984075159!5m2!1sen!2sit"
              frameBorder="0"
              style={{ border: 0, width: '100%', height: '100%' }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        {/* Contact Form */}
        <div className="col-12 col-md-6">
          <form style={{ fontFamily: "'Special Elite', system-ui" }} ref={form} onSubmit={sendEmail}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input type="text" name="user_name" className="form-control" required />
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input type="email" name="user_email" className="form-control" required />
            </div>
            <div className="mb-3">
              <label className="form-label">Subject</label>
              <input type="text" name="subject" className="form-control" required />
            </div>
            <div className="mb-3">
              <label className="form-label">Message</label>
              <textarea name="message" className="form-control" rows={5} required />
            </div>
            <button type="submit" className="btn btn-dark" style={{ fontFamily: "'Special Elite', system-ui", fontSize: '16px', paddingTop: '4px', paddingBottom: '1px', paddingLeft: '8px', paddingRight: '8px' }}>Send Message</button>
          </form>
        </div>
      </div>

      <div className="mb-5"></div> {/* Add a div with margin to create space at the end */}
    </div>
  );
};

export default Contact;
