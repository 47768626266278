import React from 'react';
import { Link } from 'react-scroll';
import blackGrapesLogoHD from '../images/blackGrapesLogoHD.png';

interface NavbarProps {
  openBasketModal: () => void;
  basketCount: number;
}

const Navbar: React.FC<NavbarProps> = ({ openBasketModal, basketCount }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid justify-content-center" style={{ fontFamily: "'Special Elite', system-ui" }}>
        {/* Brand aligned to the left */}
        <Link className="navbar-brand" to="homepage" smooth={true} duration={500}>
          InCoRo
        </Link>
        <img
        src={blackGrapesLogoHD}
        alt="Logo"
        style={{ width: '50px', height: '28px', marginLeft: '-34px', marginRight: '8px', marginBottom: '18px' }} // Adjust size and spacing
        />

        {/* Navbar toggler for small screens */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Centered and spaced navigation items */}
        <div className="collapse navbar-collapse justify-content-center justify-content-lg-center" id="navbarNav">
          <ul className="navbar-nav d-flex flex-column flex-lg-row align-items-center gap-3">
            
            <li className="nav-item">
              <Link className="nav-link" to="company-details" smooth={true} duration={500}>
                Company Details
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="wines-section" smooth={true} duration={500}>
                Wines
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="contact" smooth={true} duration={500}>
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default anchor behavior
                  openBasketModal(); // Open the basket modal using the state-based function
                }}
              >
                <div className="position-relative d-inline-block">
                  <i className="bi bi-cart"></i>
                  {basketCount > 0 && (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {basketCount}
                    <span className="visually-hidden">items in basket</span>
                  </span>
                  )}
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
