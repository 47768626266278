//started here
import React from 'react';
import axios from 'axios';

interface BasketItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  thumbnail: string;
}

interface BasketProps {
  basketItems: BasketItem[];
  removeFromBasket: (id: string) => void;
  addToBasket: (productId: string, productName: string, productPrice: number, thumbnail: string, showModal?: boolean) => void;
}

const Basket: React.FC<BasketProps> = ({ basketItems, removeFromBasket, addToBasket }) => {
  const totalPrice = basketItems.reduce((acc, item) => acc + item.price * item.quantity, 0); // Calculate total price

  // Handle checkout request
  const handleCheckout = async () => {
    try {
      const response = await axios.post('http://localhost:4000/create-checkout-session', {
        // Here you can send the basket items to your server to create the Stripe checkout session
        items: basketItems.map((item) => ({
          image: item.thumbnail,
          name: item.name,
          price: item.price,
          quantity: item.quantity,
        })),
      });

      // On success, Stripe will return a session ID. Use it to redirect to the Stripe Checkout page
      const { id: sessionId } = response.data;
      window.location.href = `https://checkout.stripe.com/pay/${sessionId}`;
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4"></h1>

      {basketItems.length === 0 ? (
        <p className='text-center' style={{ fontFamily: "'Special Elite', system-ui"}}>🍾 Your basket is empty! Fill it up with some good wine 🍷</p>
      ) : (
        <>
          <ul className="list-group mb-3">
            {basketItems.map((item) => (
              <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                <img src={item.thumbnail} alt={`${item.name} thumbnail`} style={{ 
                  width: '50px', 
                  aspectRatio: '1 / 1', // Keeps the image in a square ratio
                  objectFit: 'cover', 
                  display: 'block',  }} />
                </div>
                <div className="me-auto" style={{ fontFamily: "'Special Elite', system-ui"}}>
                  {item.name}  €{item.price.toFixed(2)}
                </div>

                <div className="d-flex align-items-center ms-auto basket-controls">
                <button
                  className="btn btn-dark btn-sm me-1 d-flex justify-content-center align-items-center"
                  style={{ 
                    width: '20px', 
                    height: '20px', 
                    padding: '0', 
                    fontWeight: 'bold',
                    borderRadius: '10%',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                   }}
                  onClick={() => addToBasket(item.id, item.name, item.price, item.thumbnail, false)}
                >
                  <i className="bi bi-plus" style={{ fontSize: '1.2rem', color: 'white' }}></i>
                </button>

                <span style={{ fontFamily: "'Special Elite', system-ui"}}>x {item.quantity}</span>

                <button
                  className="btn btn-dark btn-sm ms-1 d-flex justify-content-center align-items-center"
                  style={{ 
                    width: '20px', 
                    height: '20px', 
                    padding: '0', 
                    fontWeight: 'bold',
                    borderRadius: '10%',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}
                  onClick={() => removeFromBasket(item.id)}
                >
                  <i className="bi bi-dash" style={{ fontSize: '1.2rem', color: 'white' }}></i>
                </button>
                </div>

              </li>
            ))}
          </ul>
          <div className="d-flex justify-content-end" style={{ fontFamily: "'Special Elite', system-ui"}}>
            <h4>Total:&nbsp;</h4>
            <h4>€{totalPrice.toFixed(2)}</h4>
          </div>

          {/* Proceed to Checkout button */}
          {/*<div className="text-center">
            <button 
              type="button" 
              className="btn btn-primary mt-3"
              onClick={handleCheckout} // Link this to the checkout handler
            >
              Proceed to Checkout
            </button>
          </div>*/}
        </>
      )}
    </div>
  );
};

export default Basket;
