import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const wineSteps = ['🍷', '🍷🍷', '🍷🍷🍷', '🍷🍷🍷🍷', '🍷🍷🍷🍷🍷']; // Simulates filling

const Preloader: React.FC = () => {
  const [wineLevel, setWineLevel] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setWineLevel((prev) => (prev < wineSteps.length - 1 ? prev + 1 : prev)); // Fills glass over time
    }, 500);

    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  return (
    <div id="preloader" className="preloader">
      <div className="emoji-loader">
        🍾 {wineSteps[wineLevel]}
      </div>
      <span className="loading-text">Loading...</span>
    </div>
  );
};

export default Preloader;

/*Option 2 if needed (more basic approach)
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Preloader: React.FC = () => {
  return (
    <div id="preloader" className="preloader">
      <div className="spinner-border text-primary" role="status"></div>
      <span className="loading-text">Loading...</span> 
      </div>
    );
  };
  
  export default Preloader;
  
 */
