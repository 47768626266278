import React from 'react';

interface ItemAddedModalProps {
  show: boolean;
  productName: string | null;
  onClose: () => void;
  openBasketModal: () => void;
  closeItemAddedModal: () => void;
}

const ItemAddedModal: React.FC<ItemAddedModalProps> = ({
  show,
  productName,
  onClose,
  openBasketModal,
  closeItemAddedModal,
}) => {
  if (!show) return null;

  return (
    <div
      className={`modal fade show d-flex align-items-center justify-content-center`}
      style={{
        display: show ? 'block' : 'none',
        zIndex: 1050,
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Backdrop styling
      }}
      tabIndex={-1}
      role="dialog"
      onClick={onClose} // Close modal if backdrop is clicked
    >
      <div
        className="modal-dialog"
        role="document"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-center w-100"  style={{ fontFamily: "'Special Elite', system-ui", fontWeight: 'bold' }}>🍾 Item Added... the joy! 🍷</h5>
            {/*<button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>*/}
          </div>
          <div className="modal-body">
            <p>
              {productName ? (
                <>
                  <span style={{ fontFamily: "'Special Elite', system-ui" }}>"{productName}"</span> has been added to your basket.
                </>
              ) : (
                "The item has been added to your basket."
              )}
            </p>
          </div>
          <div className="modal-footer" style={{ justifyContent: 'center' }}>
            <button type="button" className="btn btn-dark mt-2 text-center"
              style={{ fontFamily: "'Special Elite', system-ui", fontSize: '14px', paddingTop: '4px',  // Increase padding-top to move text down
                paddingBottom: '1px', // Optional: balance padding if needed
                paddingLeft: '8px',
                paddingRight: '8px' }} onClick={onClose}>
              Keep Shopping
            </button>
            <button
              className="btn btn-dark mt-2 text-center"
              style={{ fontFamily: "'Special Elite', system-ui", fontSize: '14px', paddingTop: '4px',  // Increase padding-top to move text down
                paddingBottom: '1px', // Optional: balance padding if needed
                paddingLeft: '8px',
                paddingRight: '8px' }}
              onClick={() => {
                // Close the ItemAddedModal
                closeItemAddedModal();

                // Open the Basket Modal after a short delay
                setTimeout(() => {
                  openBasketModal();
                }, 300); // Small delay for smoother transition
              }}
            >
              View Basket
              <i className="bi bi-cart"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemAddedModal;
