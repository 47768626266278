//you started from here
import React, { useState, useEffect, useRef } from 'react';
import chiaraBrings855 from '../images/chiaraBringsNuke.jpg';
import sShape from '../images/sciroccatoShapeResized.jpg';
import maniatoPic from '../images/maniatoPNGResized.jpg';
import carbonicoPic from '../images/carbonicoPNGResized.jpg';
import sciroccatoPic from '../images/sciroccatoPNGResized.jpg';

interface BasketItem {
  id: string;
  name: string;
}

interface WinesProps {
  setSelectedProduct: React.Dispatch<React.SetStateAction<string | null>>;
  addToBasket: (productId: string, productName: string, productPrice: number, thumbnail: string) => void;
  openBasketModal: () => void;
  basketItems: BasketItem[];
  removeFromBasket: (id: string) => void;
  openWineDetailsModal: (id: string, name: string, description: string, price: number, thumbnail: string) => void;
}

const Wines: React.FC<WinesProps> = ({ setSelectedProduct, addToBasket, openBasketModal, basketItems, removeFromBasket, openWineDetailsModal }) => {
  const [sciroccatoPrice] = useState(24.90);
  const [maneggiatoPrice] = useState(29.90);
  const [carbonicoPrice] = useState(22.90);

  const wines = [
    { id: 'sciroccato-id', name: 'Sciroccato', grape: 'Nero d\'Avola', description: 'Our Nero d\'Avola PetNat with hints of spice and berry.\nBottles Produced: 75', price: sciroccatoPrice, picture: sciroccatoPic, thumbnail: sciroccatoPic, },
    { id: 'maneggiato-id', name: 'Maniato', grape: 'Pinot Noir', description: 'Fresh and explosive Pinot Noir with rich flavors and great aroma.\nBottles Produced: 256', price: maneggiatoPrice, picture: maniatoPic, thumbnail: maniatoPic, },
    { id: 'carbonico-id', name: 'Carbonico', grape: 'Nero d\'Avola', description: 'Nero d\'Avola made with carbonic maceration.\nBottles Produced: 150', price: carbonicoPrice, picture: carbonicoPic, thumbnail: carbonicoPic },
  ];

  const productRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        } else {
          entry.target.classList.remove('in-view');
        }
      });
    }, { threshold: 0.3 });

    productRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      productRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <div className="container mt-5" style={{ fontFamily: "'Special Elite', system-ui" }}>
      <h1 className="text-center mb-4" >Wines</h1>
      <p>
      We do not have a vineyard and we do not have a large piece of land… What we have though is our precious pool of selected suppliers that tirelessly support us in providing us with good and healthy grapes in time for our wine production.
      Every year, for an entire week in July and August, with the help of the ever smiling community that surround us in Carini, we load up couple of vans with empty crates, rush 🚚💨 to the scorching and equally charming Mazara del Vallo, bring breakfast to the fearless guys waiting for us at the vineyard, chuck, eat and collect a great deal of delicious Pinot Noir and Nero d’Avola, always grown with regenerative agriculture.

      </p>
      <div className="d-flex justify-content-center mt-4">
        <img src={chiaraBrings855} alt="Chiara Brings" className="img-fluid" style={{ width: '100%', maxWidth: '855px', height: 'auto', margin: '0 10px' }} />
      </div>
      <p style={{ marginTop: '20px' }}>
      Once back at the cellar the fun starts. While avoiding the use of any chemicals, we make sure the fermentation has a constant temperature so we get the right aroma and balance that we think our wine can achieve.
      Happy drinking from Carini!🥂
      </p>

      {/* Product Display */}
      <div className="d-flex justify-content-center mt-4 flex-wrap">
        {wines.map((wine, index) => (
          <div
            id='wines-section'
            className="product"
            key={wine.id}
            ref={(el) => (productRefs.current[index] = el)}
            onClick={() => setSelectedProduct(wine.name)}
          >
            <div className="d-flex flex-column align-items-center mt-2">
              <img
                src={wine.picture}
                alt={`${wine.name} Wine`}
                className="img-fluid product-img"
                style={{ width: '250px', aspectRatio: '1 / 1', objectFit: 'cover', display: 'block', margin: '0 auto', marginBottom: '10px' }}
              />
              <p className='mb-1' style={{ fontFamily: "'Special Elite', system-ui", fontSize: '18px' }}>{wine.name}</p>
              <p className='mb-1'>{wine.grape}</p>

              <a
                className="btn btn-dark btn-sm mt-2"
                style={{ fontFamily: "'Special Elite', system-ui", fontSize: '14px', paddingTop: '4px', paddingBottom: '1px', paddingLeft: '8px', paddingRight: '8px' }}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  openWineDetailsModal(wine.id, wine.name, wine.description, wine.price, wine.thumbnail);
                }}
              >
                View Details
              </a>

              <a
                className="btn btn-dark mt-2"
                style={{ fontFamily: "'Special Elite', system-ui", fontSize: '14px', paddingTop: '4px', paddingBottom: '1px', paddingLeft: '8px', paddingRight: '8px' }}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  addToBasket(wine.id, wine.name, wine.price, wine.picture);
                }}
              >
                Add to Basket
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wines;