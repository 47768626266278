import React, { useRef, useState, useEffect } from 'react'; 
import chiaraInVineyard from '../images/vigna_1024x768.jpg';
import chiaraProfile from '../images/bicchiere_chiara_1024x768.jpg';
import cakeBW from '../images/torta_1024x768.jpg';
import armWithGlass from '../images/armWithGlass.jpg';
import chiaraSqueezes from '../images/chiaraSqueezes.jpg';
import chiaraPlaces from '../images/chiaraPlaceGrapes.jpg';

const CompanyDetails: React.FC = () => {
  const carouselWrapperRef = useRef<HTMLDivElement>(null);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const imagesToShow = 3; // Number of images to display
  const totalImages = 6;  // Total number of images
  const [imageWidth, setImageWidth] = useState(0); // Dynamic image width

  const images = [
    chiaraInVineyard, chiaraProfile, cakeBW, armWithGlass, chiaraSqueezes, chiaraPlaces
  ];

  // Clone the first few images to the end for seamless looping
  const allImages = [...images, ...images.slice(0, imagesToShow)];

  // Set image width dynamically based on the first image
  useEffect(() => {
    if (carouselWrapperRef.current) {
      const firstImage = carouselWrapperRef.current.querySelector('.carousel-image') as HTMLElement;
      if (firstImage) {
        setImageWidth(firstImage.offsetWidth + 20); // Adding margin
      }
    }
  }, []);

  // Sliding function for automatic sliding
  useEffect(() => {
    const intervalId = setInterval(() => {
      slideNext(); // Automatically slide to the next image
    }, 3000); // Change every 3 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [imageWidth, currentPosition]);

  const slideNext = () => {
    if (carouselWrapperRef.current) {
      setIsTransitioning(true);
      setCurrentPosition(prevPosition => prevPosition - imageWidth);
    }
  };

  // Reset position if reached the end of cloned images after the transition
  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentPosition <= -(imageWidth * totalImages)) {
        // Instantly reset to the first image without transition
        setIsTransitioning(false);
        setCurrentPosition(0);
      }
    };

    if (carouselWrapperRef.current) {
      carouselWrapperRef.current.addEventListener('transitionend', handleTransitionEnd);
      return () => {
        carouselWrapperRef.current?.removeEventListener('transitionend', handleTransitionEnd);
      };
    }
  }, [currentPosition, imageWidth]);

  // Apply the translateX style and manage transition
  useEffect(() => {
    if (carouselWrapperRef.current) {
      if (isTransitioning) {
        carouselWrapperRef.current.style.transition = 'transform 0.5s ease-in-out'; // Smooth transition
      } else {
        carouselWrapperRef.current.style.transition = 'none'; // No transition for instant reset
      }
      carouselWrapperRef.current.style.transform = `translateX(${currentPosition}px)`;
    }
  }, [currentPosition, isTransitioning]);

  return (
    <div className="container mt-5" style={{ fontFamily: "'Special Elite', system-ui" }}>
      <h1 className="text-center mb-4">Company Details</h1>
      <p>
      We are located on the wild western side of Sicily, enveloped in between the golden coast and the rocky mountains of the ever-expanding town of Carini. After many years working in different fields we have embarked on a new chapter of our lives by becoming urban natural wine makers. 
      </p>
      <p>
      Don’t let the small size of our winery fool you. We firmly consider ourseleves to be a boutique where serious winemaking is going on! We’re true believers in minimal intervention winemaking, resulting in wines that are not only fun to drink but also have a great connection with the Sicilian country side. We do just enough to allow our grapes and yeast to do the rest. 
      </p>

      {/* Carousel */}
      <div className="carousel-container mt-4">
        <div className="carousel-wrapper" ref={carouselWrapperRef} style={{ display: 'flex' }}>
          {allImages.map((image, index) => (
            <img key={index} src={image} alt={`Image ${index + 1}`} className="img-fluid carousel-image" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
