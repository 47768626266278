import React from 'react';


const Footer: React.FC = () => {
  return (
    <footer className="footer" style={{ fontFamily: "'Special Elite', system-ui" }}>
      <p>&copy; 2023 InCoRo. All rights reserved.</p>
      <div className="social-icons">
        <a href="https://instagram.com" className="icon" target="_blank" rel="noopener noreferrer">
          <i className="bi bi-instagram"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
