// SuccessModal.tsx
import React from 'react';

interface SuccessModalProps {
  show: boolean;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div
      className={`modal fade show d-flex align-items-center justify-content-center`}
      style={{
        display: show ? 'block' : 'none',
        zIndex: 1050,
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Backdrop styling
      }}
      tabIndex={-1}
      role="dialog"
      onClick={onClose} // Close modal if backdrop is clicked
    >
      <div
        className="modal-dialog"
        role="document"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title text-center w-100"
              style={{ fontFamily: "'Special Elite', system-ui", fontWeight: 'bold' }}
            >
              🎉 Wine on the way! 🎉
            </h5>
          </div>
          <div className="modal-body">
            <p style={{ fontFamily: "'Special Elite', system-ui" }}>
              Your order was successfully placed. We'll be in touch soon with updates about its status. Happy Drinking! 
            </p>
          </div>
          <div className="modal-footer" style={{ justifyContent: 'center' }}>
            <button
              type="button"
              className="btn btn-dark mt-2 text-center"
              style={{
                fontFamily: "'Special Elite', system-ui",
                fontSize: '14px',
                paddingTop: '4px',
                paddingBottom: '1px',
                paddingLeft: '8px',
                paddingRight: '8px',
              }}
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
