import React, { useState, useEffect } from 'react';
import backgroundImage from '../images/chiaraLookingHorizon.jpg';
import whiteGrapes from '../images/whiteGrapesLogoHD.png';

const Homepage = () => {
  const words = ['makers', 'lovers', 'drinkers'];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayedWord, setDisplayedWord] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [charIndex, setCharIndex] = useState(0);
  const [speed, setSpeed] = useState(200); // typing speed in ms

  useEffect(() => {
    const currentWord = words[currentWordIndex];
    
    // Function to update displayed word
    const updateWord = () => {
      if (!isDeleting && charIndex < currentWord.length) {
        // Typing the word
        setDisplayedWord(currentWord.substring(0, charIndex + 1));
        setCharIndex(charIndex + 1);
        setSpeed(200); // Normal typing speed
      } else if (isDeleting && charIndex > 0) {
        // Deleting the word
        setDisplayedWord(currentWord.substring(0, charIndex - 1));
        setCharIndex(charIndex - 1);
        setSpeed(100); // Faster deleting speed
      } else if (!isDeleting && charIndex === currentWord.length) {
        // Pause before deleting
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && charIndex === 0) {
        // Switch to next word and start typing again
        setIsDeleting(false);
        setCurrentWordIndex((prev) => (prev + 1) % words.length);
      }
    };

    const typingTimeout = setTimeout(updateWord, speed);

    return () => clearTimeout(typingTimeout);
  }, [charIndex, isDeleting, currentWordIndex, speed, words]);

  return (
    <div
      className="homepage-container d-flex justify-content-center align-items-center"
      style={{ fontFamily: "'Special Elite', system-ui", height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <div className="text-center text-white">
      <div className="d-flex align-items-center justify-content-center" style={{marginBottom: '-20px'}}>
          <h1 style={{ marginRight: '-30px', marginLeft: '2px', }}>InCoRo</h1> {/* Add margin-right to space out the logo */}
          <img
            src={whiteGrapes}
            alt="White Grapes Logo"
            style={{ height: '50px', width: 'auto', marginBottom: '40px' }} // Adjust size if needed
          />
        </div>
        <h4>
          We are wine {' '}
          <span className="typed-text">{displayedWord}</span>
          <span className="cursor" style={{ borderRight: '2px solid white' }}></span>
        </h4>
      </div>
    </div>
  );
};

export default Homepage;
